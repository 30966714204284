<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="50%">
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>
                        {{ $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل" }}
                        {{ this.$route.meta.single }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    <!-- imageUrl -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">اضافة صورة</h4>
                                        <FileUpload
                                            accept="image/*;capture=camera"
                                            formData="files"
                                            endPoint="UploadFile/postUploadFiles"
                                            buttonText="رفع صورة"
                                            icon="true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="primary"
                                    class="secondary--text btn"
                                    :disabled="!valid || !this.$store.state.files[0]"
                                >حفظ</v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2 btn"
                                    outlined
                                >إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";

export default {
    components: {
        FileUpload,
    },

    data() {
        return {
            valid: false,
            item: {},
        };
    },

    mounted() {
        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
        });
    },

    methods: {
        reset() {
            this.item = {
                imageUrl: "",
            };
            this.$store.commit("clearFiles");
        },

        submit() {
            this.$refs.form.validate();
            if (this.$store.state.itemDetails.dialogType == "add") {
                this.addItem();
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.imageUrl = res.data.result.fileList[0].path;
                    });
            }
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>



